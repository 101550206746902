export default {
  apiGateway: {
    REGION: 'eu-west-1',
    URL: 'https://5onw4b3jfi.execute-api.eu-west-1.amazonaws.com/dev',
  },
  cognito: {
    REGION: 'eu-west-1',
    USER_POOL_ID: 'eu-west-1_R40DDkD9M',
    APP_CLIENT_ID: '4ctlj1d0783eu0t1r1rsp5gmnb',
    IDENTITY_POOL_ID: 'eu-west-1:7e3d1edf-e199-4b70-88eb-1cb7e4f5cc75',
  },
};
